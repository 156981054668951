
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .signaturePageCss{
        width: 100%;
    }
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .signaturePageCss{
        width: 50%;
    }    
  }