:root{
  --app-font-family: 'Roboto' !important;
  --app-color: #3b276a !important;
}

*{
  font-Family: var(--app-font-family);
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-Family: var(--app-font-family);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

input:disabled {
  -webkit-text-fill-color: #4f4f4f;
  border-color: #AFAFB3;
}

#startTime:disabled, #appointmentDate,#startTimeForAppintment {
  -webkit-text-fill-color: #888888;
}

#appointmentDate {
  height: 9px;
  font-Size: 16px;
  font-Weight: normal;
  font-Style: normal;
  color: #a0a0a0;
}

#appointmentDate:disabled {
  height: 23px;
  font-Size: 16px;
  font-Weight: normal;
  font-Style: normal;
  color: #a0a0a0;
}

#startTime, #endTime, #appointmentStartDate, #appointmentEndDate {
  color: #4f4f4f; 
}

.rotateScreen{
  transform-origin: right top;
  width: 100vh;
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  transform: rotate(90deg);
}

.portrait{
  transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #FFFFFF !important;
  color: #888888 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #888888!important;
}

.MuiInputBase-input.Mui-disabled {
  padding: 9px;
  border-color: #AFAFB3 !important;
}

input:disabled,
textarea:disabled,
.MuiInputBase-input.Mui-disabled,
.MuiSelect-select.Mui-disabled {
  background-color: #ededed !important;
}

.mobile__pdf__container #viewerContainer {
  top: 5% !important;
  bottom: 10% !important;
  right: 20px !important;
}

.pdfViewer .page {
  border: none !important;
  background-clip: unset !important;
  border-image: none !important;
}

.mobile__pdf__container #viewerContainer .pdfViewer {
  overflow: visible !important;
}

.mobile__pdf__container footer {
  background-image: none !important;
  box-shadow: none !important;
  background-color: #000 !important;
  border-radius: 30px;
  opacity: 0.6;
  bottom: 5% !important;
  left: inherit !important;
  right: 5% !important;
  width: 18% !important
}

.mobile__pdf__container .toolbarButton.pageUp, .mobile__pdf__container .toolbarButton.pageDown, .mobile__pdf__container #pageNumber {
  display: none !important;
}

.mobile__pdf__container .toolbarButton.zoomOut {
  left: 15% !important;
  width: 30% !important;
}

.mobile__pdf__container .toolbarButton.zoomIn {
  width: 30% !important;
  left: 60% !important;
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px){
      .mobile__pdf__container footer {
        width: 40% !important;
        height: 50px !important;
        bottom: 10% !important;
    }

    .mobile__pdf__container .toolbarButton.zoomOut, .mobile__pdf__container .toolbarButton.zoomIn {
      background-size: 60% !important;
    }
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: var(--app-color) !important;
}

.MuiButtonBase-root.MuiButton-root {
  color: var(--app-color);
}

.PrivatePickersYear-yearButton.Mui-selected {
  background-color: var(--app-color) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--app-color) !important;
}

.noDataStyle {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  font-weight: bold
}

.wrapText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}