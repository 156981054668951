.floatingErrorMessage{
    margin: 10px 15px 0px 15px;
    padding: 15px 8px 15px 8px;
    background-repeat: no-repeat;
    background-position: 10px center;
    text-align: center;
    color: rgb(211, 0, 0);
    font-size: 12px;
    letter-spacing: 0.09px;
    background-color: #FEE5E0;
    font-weight: bold;
}

.fade-in {
    opacity: 1;
    width: 96%;
    height: 50px;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    width: 0;
    height: 0;
    display: none;
}

@keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .floatingErrorMessage {
        width: 92%;
        font-size: 12px;
    }
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .floatingErrorMessage {
        width: 96%;
        font-size: 14px;
    }
}

.serviceDocNamefForHandheld {
    height: 22px;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.08pt;
    color: var(--app-color);
    padding: 10px 0px 30px 17px;
    display: inline-block;
    width: -webkit-fill-available;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
 }

 .configurableFormName {
    height: 22px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.04pt;
    color: var(--app-color);
    padding: 0px 0px 30px 0px;
    display: inline-block;
    width: -webkit-fill-available;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
 }