.phoneField{
    display: inline-flex;
    /* column-gap: 10px; */
    align-items: center;
}

.dash{
    font-size: 23px;
    font-weight: normal;
    padding: 0px 7.2px 0px 9px;
}

.ext{
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    padding: 0px 4px 0px 12px;
}

.dialogTitle {
    height: 22px;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    color: var(--app-color);
    padding: 0px 0px 9px 0px;
    display: flex;
    align-items: center;
}

.dateTimeField {
    width: 100%;
    display: grid;
    grid-template-columns: 226px 103px;
    column-gap: 15px;
    align-items: flex-end;
}