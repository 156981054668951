.serviceDocName {
    height: 22px;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    color: var(--app-color);
    padding: 15px 0px 35px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: -21px;
    display: block;
 }
 
 .serviceDocumentName {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
